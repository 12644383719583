.app-container {
    text-align: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  header {
    background-color: #6200ea;
    color: white;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
  }
  
  .dream-input {
    margin-bottom: 2rem;
  }
  
  .dream-input input {
    padding: 0.5rem;
    width: 300px;
    font-size: 1rem;
  }
  
  .dream-input button {
    padding: 0.5rem 1rem;
    background-color: #03dac5;
    border: none;
    color: white;
    font-size: 1rem;
    margin-left: 1rem;
    cursor: pointer;
  }
  
  .dream-list {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .dream-item {
    background-color: #bb86fc;
    padding: 1rem;
    margin: 0.5rem 0;
    color: white;
    border-radius: 5px;
  }
  